const getOrdinalDateString = () => {
  let date = new Date().toLocaleDateString("en-US", {
    weekday: "long",
    month: "long",
    day: "numeric",
  });

  const day = parseInt(date.split(" ").pop());
  let ordinary = "th";

  if (day === 1) {
    ordinary = "st";
  } else if (day === 2) {
    ordinary = "nd";
  } else if (day === 3) {
    ordinary = "rd";
  }

  return date + ordinary;
};

const getTimeString = () =>
  new Date().toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });

const getDateTime = (time) => {
  const date = new Date().toLocaleDateString();
  const regex = /([01][0-9]|2[0-3]):[0-5][0-9]/g;
  const isPM = time.substr(time.length - 2) === "PM";
  const regexEventTime = time?.match(regex);
  const milliSecondsEventTime =
    isPM && time.substr(0, 2) !== "12"
      ? Date.parse(`${date} ${regexEventTime}`) + 12 * 60 * 60 * 1000
      : Date.parse(`${date} ${regexEventTime}`);
  return milliSecondsEventTime;
};

const calculateIsYoutube = (url) => {
  const regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  return regExp.test(url);
};

const calculateIsInTime = (time) => {
  let isInTime = false;
  if (time) {
    const currentTime = new Date().getTime();
    const milliSecondsEventTime = getDateTime(time);
    isInTime = milliSecondsEventTime >= currentTime;
  }
  return isInTime;
};

const convert12to24 = (hour) => {
  const time = hour;
  let hours = Number(time.match(/^(\d+)/)[1]);
  const minutes = Number(time.match(/:(\d+)/)[1]);
  const AM_PM = time.match(/\s(.*)$/)[1];

  if(AM_PM === 'PM' && hours < 12) hours = hours + 12;
  if(AM_PM === 'AM' && hours === 12) hours = hours - 12;

  const formattedHours = hours < 10 ? [0, hours].join('') : hours.toString();
  const formattedMinutes = minutes < 10 ? [0, minutes].join('') : minutes.toString();

  return [formattedHours,':', formattedMinutes].join('');

}; 

const getMinutesFromTime = (hour) => {
  return Number(hour.match(/:(\d+)/)[1]);
}

const getHourFromTime = (hour) => {
  return Number(hour.match(/(\d+):/)[1]);
}

const isLiveEvent = (link) => {
  return link.toLowerCase().includes('zoom') || link.toLowerCase().includes('meet.google.com');
}

const isExternalEvent = (link) => {
  // only certain websites are allowed to be opened in a new tab
  return /abcnews|wgntv|tv.youtube.com|sling.com|25newsnow.com|paramountplus.com|activityconnection.com|letsplaybingo.io|sudoku.com|razzlepuzzles.com|thewordsearch.com|nytimes.com|boatloadpuzzles.com|jeopardylabs.com|wcia.com|activityconnection.com/.test(link);
}

const renderClassName = (time, link) => {
  const eventClass = "event";
  const disabledEventClass = "disabled_event";
  // this is needed in case an activity block was created with no events
  if (!link) {
      return eventClass;
  } else {
      link = link.toLowerCase();
  }

  if(isLiveEvent(link)) {
      const isInTime = calculateIsInTime(time);
      return time === "00:00" || !isInTime ? disabledEventClass : eventClass;
  } else if (link.trim() === '') {
      return disabledEventClass;
  } else {
      return eventClass;
  }
}

const resetEventCarousel = (eventList) => {
  eventList.forEach(function(event) {
    if(document.getElementById(event.id)) {
        document.getElementById(event.id).className = renderClassName(event.end_time, event.related_link);
        event.showRatings = false;
    }
  });
}

const shuffleEvents = (events, recursionCount) => {
  // currently we are shuffling the events in an activity block by having a different event start
  // each day. we will accomplish this by having the first event show on monday, second on tuesday, etc.
  // the recursionCount defaults to the decimal of the previous day. 0 is sunday and 6 is saturday
  if(recursionCount <= 0 || events.length < 7) {
    return events;
  } else {
    // grab the first event in the array
    let firstEvent = events.shift();

    // insert the event at location 6 because that takes the popped event into account
    events.splice(6, 0, firstEvent);
    return shuffleEvents(events, recursionCount - 1);
  }
}

export {
  getOrdinalDateString,
  getTimeString,
  calculateIsInTime,
  getDateTime,
  calculateIsYoutube,
  convert12to24,
  getMinutesFromTime,
  getHourFromTime,
  isLiveEvent,
  isExternalEvent,
  renderClassName,
  resetEventCarousel,
  shuffleEvents,
};
